// EXTERNAL IMPORTS
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps, useDisclosure } from "@chakra-ui/react";
import React, { createContext, RefObject, useContext, useState } from "react";

// TYPES
export type ModalContextType = {
  content: React.ReactNode | null;
  title: string | null;
  size: ModalProps["size"] | null;
  initialFocusRef?: RefObject<HTMLElement>;
  openModal: (title: string, content: React.ReactNode, size?: ModalProps["size"], focusRef?: RefObject<HTMLElement>) => void;
  closeModal: () => void;
};

// CONTEXT
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// COMPONENT
export function ModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const [initialFocusRef, setInitialFocusRef] = useState<React.RefObject<HTMLElement> | null>(null);

  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [size, setSize] = useState<ModalProps["size"] | null>(null);

  function openModal(newTitle: string, newContent: React.ReactNode, size?: ModalProps["size"], focusRef?: React.RefObject<HTMLElement>) {
    setContent(newContent);
    if (newTitle) {
      setTitle(newTitle);
    }
    setSize(size || "md");
    setInitialFocusRef(focusRef || null); // set the ref
    onModalOpen();
  }

  function closeModal() {
    setContent(null);
    setTitle(null);
    onModalClose();
  }

  return (
    <ModalContext.Provider value={{ title, content, size, openModal, closeModal }}>
      {children}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} isCentered size={size || "md"} initialFocusRef={initialFocusRef || undefined}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pt={0} pb={6}>
              {content}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </ModalContext.Provider>
  );
}

// HOOK
export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}

// EXPORT
export default ModalContext;
